import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { defaultLineColor } from '../../theme';
import { MAX_WIDTH_MIDDLE_CONTAINER } from './BottomReservationButtonForPublicEvent';

type PageAlign = 'center' | 'left';

export const PageContainer = styled.div<{
    withBorder: boolean;
    withPaddingTop: boolean;
    withPaddingBottom: boolean;
    align: PageAlign;
}>`
    display: flex;
    padding: ${({ withPaddingTop, withPaddingBottom }) =>
        `${withPaddingTop ? '20px' : '0px'} 0px ${withPaddingBottom ? '20px' : '0px'} 0px`};
    flex-direction: column;
    border-top: ${props => (props.withBorder ? `1px solid ${defaultLineColor}` : 0)};
    align-items: ${props => `${props.align}`};

    @media (max-width: 767px) {
        padding: ${({ withPaddingTop, withPaddingBottom }) =>
            `${withPaddingTop ? '5px' : '0px'} 0px ${withPaddingBottom ? '20px' : '0px'} 0px`};
    }
`;

export type PageProps = {
    withBorder?: boolean;
    withPaddingTop?: boolean;
    withPaddingBottom?: boolean;
    withMiddleContainer?: boolean;
    maxWidth?: number | 'none';
    withTableContainer?: boolean;
    headerTitle?: string | null;
    align?: PageAlign;
    children: ReactNode;
};

export const Page: FC<PageProps> = ({
    withPaddingTop = true,
    withPaddingBottom = true,
    withBorder = true,
    withMiddleContainer = false,
    maxWidth = MAX_WIDTH_MIDDLE_CONTAINER,
    withTableContainer = false,
    headerTitle,
    align = 'center',
    children,
}) => {
    // Jak babu pridala do headeru shadow, tak se to s tim tluce, tak zatim vzdy bez border
    withBorder = false;

    return (
        <PageContainer
            withBorder={withBorder}
            withPaddingTop={withPaddingTop}
            withPaddingBottom={withPaddingBottom}
            align={align}
        >
            {headerTitle && <h2 className="settings-heading">{headerTitle}</h2>}
            {withMiddleContainer || withTableContainer ? (
                <div
                    style={{
                        ...(withMiddleContainer && {
                            maxWidth: `${maxWidth}px`,
                        }),
                    }}
                    className={withMiddleContainer ? 'page-container-middle' : 'page-container-table'}
                >
                    {children}
                </div>
            ) : (
                children
            )}
        </PageContainer>
    );
};
