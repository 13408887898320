import { officeUserRoles } from '@fyooga/codebook';
import { parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../components/basics/Icon/Icon';
import { TooltipResponsive } from '../components/TooltipResponsive';
import { APPLICATION_SUBDOMAIN } from '../constants';
import { useEmployeeCurrentHandledOfficeData } from '../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { UNLIMITED_ORDERS_CONSTANT, useCheckTariffLimits } from '../hooks/useCheckTariffLimits';
import { useHasUserAccessByOfficeRole } from '../hooks/useHasUserAccessByOfficeRole';
import { useFormatters } from '../lib/formatters';
import {
    APP_TAB_KEY_OFFICE,
    SETTINGS_TAB_KEY_CLIENTS,
    SETTINGS_TAB_KEY_ORDERS,
    SETTINGS_TAB_KEY_STATISTICS,
    SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW,
    SETTINGS_TAB_KEY_WORKSHOPS,
} from '../router/constants';
import { primaryColor } from '../theme';
import { OfficeT } from '../types/office';
import { MaxOrdersProgressCircle } from './headers/MaxOrdersProgressCircle';

const SidebarMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
`;

export const SidebarMobileMenu = ({
    mobile = false,
    closeMobileMenuDrawer,
    office,
}: {
    mobile?: boolean;
    closeMobileMenuDrawer?: () => void;
    office: OfficeT;
}) => {
    const { t } = useTranslation();
    const { DateFormatter } = useFormatters();

    const { officeId } = office;
    const { data } = useCheckTariffLimits(office);

    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData(officeId);

    const currentHandledOfficeId = employeeCurrentHandledOfficeData?.officeId;

    const {
        hasAccess: canUserAccessSettingsForRoleManagerOrSuperadmin,
        loading: loadingCanUserAccessSettingsForRoleManagerOrSuperadmin,
    } = useHasUserAccessByOfficeRole(currentHandledOfficeId, [officeUserRoles.MANAGER, officeUserRoles.SUPERADMIN]);
    const {
        hasAccess: canUserAccessSettingsForSuperadminRole,
        loading: loadingCanUserAccessSettingsForSuperadminRole,
    } = useHasUserAccessByOfficeRole(currentHandledOfficeId, [officeUserRoles.SUPERADMIN]);

    const allCheckHasAccessLoaded =
        !loadingCanUserAccessSettingsForRoleManagerOrSuperadmin && !loadingCanUserAccessSettingsForSuperadminRole;

    const handleNavLinkClick = () => {
        if (mobile && closeMobileMenuDrawer) {
            closeMobileMenuDrawer();
        }
    };

    const navLinkProps = {
        className: 'sidebar-menu__item',
        activeClassName: 'sidebar-menu__item-selected',
        onClick: handleNavLinkClick,
    };

    const iconSize = mobile ? 20 : 40;

    return (
        <>
            {employeeCurrentHandledOfficeData && currentHandledOfficeId && allCheckHasAccessLoaded && (
                <>
                    {/*
                    <NavLink exact to={`/${APPLICATION_SUBDOMAIN}`} {...navLinkProps}>
                        {mobile ? (
                            <p>{t('sidebar.dashboard')}</p>
                        ) : (
                            <TooltipResponsive title={t('sidebar.dashboard')}>
                                <Icon icon="Workspace" size={iconSize} iconColor={primaryColor} />
                            </TooltipResponsive>
                        )}
                    </NavLink>
                    */}
                    <NavLink
                        exact
                        to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}`}
                        {...navLinkProps}
                    >
                        {mobile ? (
                            <SidebarMenuItem>
                                <Icon icon="Calendar1" size={iconSize} iconColor={primaryColor} />
                                <span>{t('sidebar.calendar')}</span>
                            </SidebarMenuItem>
                        ) : (
                            <TooltipResponsive title={t('sidebar.calendar')}>
                                <Icon icon="Calendar" size={iconSize} iconColor={primaryColor} />
                            </TooltipResponsive>
                        )}
                    </NavLink>
                    {canUserAccessSettingsForRoleManagerOrSuperadmin && (
                        <NavLink
                            exact
                            to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_WORKSHOPS}`}
                            {...navLinkProps}
                        >
                            {mobile ? (
                                <SidebarMenuItem>
                                    <Icon icon="GraduationCap" size={iconSize} iconColor={primaryColor} />
                                    <span>{t('sidebar.workshops')}</span>
                                </SidebarMenuItem>
                            ) : (
                                <TooltipResponsive title={t('sidebar.workshops')}>
                                    <Icon icon="GraduationCap" size={iconSize} iconColor={primaryColor} />
                                </TooltipResponsive>
                            )}
                        </NavLink>
                    )}
                    {canUserAccessSettingsForRoleManagerOrSuperadmin && (
                        <>
                            <NavLink
                                exact
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_CLIENTS}`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Clients" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.clients')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <TooltipResponsive title={t('sidebar.clients')}>
                                        <Icon icon="Clients" size={iconSize} iconColor={primaryColor} />
                                    </TooltipResponsive>
                                )}
                            </NavLink>
                            <NavLink
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_ORDERS}`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="ShoppingCart" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.orders')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <TooltipResponsive title={t('sidebar.orders')}>
                                        <Icon icon="ShoppingCart" size={iconSize} iconColor={primaryColor} />
                                    </TooltipResponsive>
                                )}
                            </NavLink>
                        </>
                    )}
                    {canUserAccessSettingsForSuperadminRole && (
                        <>
                            <NavLink
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW}`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Subscriptions" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.subscription')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <TooltipResponsive title={t('sidebar.subscription')}>
                                        <Icon icon="Subscriptions" size={iconSize} iconColor={primaryColor} />
                                    </TooltipResponsive>
                                )}
                            </NavLink>
                            <NavLink
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_STATISTICS}/revenues`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Statistics" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.statistics')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <TooltipResponsive title={t('sidebar.statistics')}>
                                        <Icon icon="Statistics" size={iconSize} iconColor={primaryColor} />
                                    </TooltipResponsive>
                                )}
                            </NavLink>
                        </>
                    )}
                    {canUserAccessSettingsForRoleManagerOrSuperadmin && (
                        <NavLink
                            to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/settings`}
                            {...navLinkProps}
                        >
                            {mobile ? (
                                <SidebarMenuItem>
                                    <Icon icon="SettingsGears" size={iconSize} iconColor={primaryColor} />
                                    <span>{t('sidebar.settings')}</span>
                                </SidebarMenuItem>
                            ) : (
                                <TooltipResponsive title={t('sidebar.settings')}>
                                    <Icon icon="SettingsGears" size={iconSize} iconColor={primaryColor} />
                                </TooltipResponsive>
                            )}
                        </NavLink>
                    )}
                    {data && data.maxAllowedOrdersCount !== UNLIMITED_ORDERS_CONSTANT && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                textAlign: 'center',
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: '10px',
                                }}
                            >
                                <MaxOrdersProgressCircle
                                    current={data.totalOrdersCount}
                                    max={data.maxAllowedOrdersCount}
                                    type="circle"
                                />
                            </div>
                            <div>
                                {t('subscriptionNs:freeRemainingOrders', {
                                    remainingOrders: data.maxAllowedOrdersCount - data.totalOrdersCount,
                                    validFrom: DateFormatter.format(parseISO(data.tariffMonthlyLimitValidFrom), {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    }),
                                    validTo: DateFormatter.format(parseISO(data.tariffMonthlyLimitValidTo), {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    }),
                                    classPlural: t('plurals.order', {
                                        postProcess: 'interval',
                                        count: data.maxAllowedOrdersCount - data.totalOrdersCount,
                                    }),
                                })}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
