import Alert from '@mui/material/Alert';
import { AlertProps } from '@mui/material/Alert/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ReactNode } from 'react';

type AlertMuiCustomProps = Pick<AlertProps, 'severity' | 'icon'>;
interface AlertMuiProps extends AlertMuiCustomProps {
    children: ReactNode;
    title?: ReactNode;
    marginTop?: number;
    marginBottom?: number;
}

const alertColors = {
    info: {
        main: '#663399',
        light: 'rgba(102, 51, 153, 0.05)',
        border: 'rgba(102, 51, 153, 0.2)',
    },
    success: {
        main: '#43a047',
        light: 'rgba(67, 160, 71, 0.05)',
        border: 'rgba(67, 160, 71, 0.2)',
    },
    warning: {
        main: '#eda21c',
        light: 'rgba(237, 162, 28, 0.05)',
        border: 'rgba(237, 162, 28, 0.2)',
    },
    error: {
        main: '#d32f2f',
        light: 'rgba(211, 47, 47, 0.05)',
        border: 'rgba(211, 47, 47, 0.2)',
    },
};

const getAlertStyles = (severity?: AlertProps['severity']) => {
    const baseStyles = {
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        padding: '12px 16px',
        border: '1px solid',
    };

    if (!severity || !alertColors[severity]) {
        return baseStyles;
    }

    const colors = alertColors[severity];

    return {
        ...baseStyles,
        backgroundColor: colors.light,
        borderColor: colors.border,
        '& .MuiAlert-icon': {
            color: colors.main,
        },
        '& .MuiAlert-message': {
            color: '#2c2c2c',
        },
        '& .MuiSvgIcon-root': {
            color: colors.main,
        },
    };
};

export const AlertMui = ({ severity, icon, title, marginTop, marginBottom = 15, children }: AlertMuiProps) => {
    return (
        <Alert
            severity={severity}
            icon={icon}
            sx={getAlertStyles(severity)}
            style={{
                marginBottom: `${marginBottom}px`,
                marginTop: marginTop ? `${marginTop}px` : 0,
                textAlign: 'left',
            }}
        >
            {title && (
                <AlertTitle
                    style={{
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        marginBottom: '10px',
                        color: '#2c2c2c',
                    }}
                >
                    {title}
                </AlertTitle>
            )}
            {children}
        </Alert>
    );
};
