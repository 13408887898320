import React, { Component } from 'react';

import { AuthorizedLayoutComponent } from '../layout/AuthorizedLayoutComponent';
import { PublicLayoutComponent } from '../layout/PublicLayoutComponent';
import { ErrorNetworkProblems } from '../pages/errors';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // Use to render a fallback UI after an error has been thrown.
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    // Use to log error information
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service like Sentry/LogRocket
        // this.logger.e('Uncaught error:', error);
        // this.logger.e('Uncaught error info:', errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children, isLoggedIn } = this.props;

        // You can render any custom fallback UI
        const getErrorBoundaryContent = () => (
            <>
                {isLoggedIn ? (
                    <AuthorizedLayoutComponent isSidebarVisible={false}>
                        {hasError ? <ErrorNetworkProblems /> : <>{children}</>}
                    </AuthorizedLayoutComponent>
                ) : (
                    <PublicLayoutComponent>
                        {hasError ? <ErrorNetworkProblems /> : <>{children}</>}
                    </PublicLayoutComponent>
                )}
            </>
        );

        return <>{hasError ? getErrorBoundaryContent() : <>{children}</>}</>;
    }
}
